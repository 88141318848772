import React from 'react'
import { graphql, Link } from 'gatsby'
import styles from './docs.module.css'

import Layout from '../layouts/docs.js'
import Helmet from 'react-helmet'
import DocumentationNav from '../components/DocumentationNav'

export default class DocsIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title={'Documentation | Suggested - Build better products'}
        ></Helmet>
        <div className={styles.root}>
          <div className={styles.nav}>
            <DocumentationNav docs={this.props.data.docs.edges} />
          </div>
          <div className={styles.container}>
            <h1>Documentation</h1>

            <div className={styles.intro}>
              <p>
                Welcome to Suggested's documentation, please take look at our{' '}
                <Link to={'/docs/getting-started-with-suggested'}>
                  quick start guide
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query DocsQuery {
    docs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: { frontmatter: { type: { eq: "documentation" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            type
            description
          }
          html
        }
      }
    }
  }
`
